<template>
  <el-card id="note">
    <header>
      <h1>号码检测管理</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业编号</span>
            <el-input
              v-model="id"
              placeholder="请输入企业编号"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>企业编号</span>
            <el-select v-model="numStatus" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        v-loading="loading"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="企业编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="公司名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rate"
          label="费率"
          align="center"
        ></el-table-column>

        <el-table-column prop="s_tatus" label="开通时间" align="center">
          <template #default="scope">
            {{ scope.row.createTime |formatDate('yyyy-MM-dd HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column prop="s_tatus" label="是否开通号码检测" align="center">
          <template #default="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              active-text="已开通"
              inactive-text="已关闭"
              @change="changeSwitch($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="mini"
              >编辑</el-button
            >
            <!-- <el-button type="primary" @click="updateadd(scope)" size="mini"
              >充值</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="updateVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="开通费率">
          <el-input
            v-model="form.rate"
            style="width: 220px"
            placeholder="请输入开通费率"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer style="position: relative">
        <span class="dialog-footer">
          <el-button type="primary" @click="updateVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 充值弹出框 -->
    <el-dialog title="充值" :visible.sync="updateaddVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="充值金额">
          <el-input
            v-model="form.count"
            style="width: 220px"
            placeholder="请输入充值金额"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer style="position: relative">
        <span class="dialog-footer">
          <el-button type="primary" @click="updateaddVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateaddSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { list, update } from "@/api/NnumberDetection";
export default {
  data() {
    return {
      numStatus: null,
      options: [
        {
          value: "0",
          label: "未开通",
        },
        {
          value: "1",
          label: "已开通",
        },
      ],
      form: {},
      id: "",
      updateVisible: false,
      updateaddVisible: false,
      tableData: [], //表格内绑定的数据
      loading: true,
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.getList();
  },
  filters: {
    formatDate: function (value, args) {
      var dt = new Date(value);
      if (args == "yyyy-M-d") {
        // yyyy-M-d
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        return `${year}-${month}-${date}`;
      } else if (args == "yyyy-M-d H:m:s") {
        // yyyy-M-d H:m:s
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      } else if (args == "yyyy-MM-dd") {
        // yyyy-MM-dd
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let date = dt.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${date}`;
      } else {
        // yyyy-MM-dd HH:mm:ss
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2, "0");
        let date = dt.getDate().toString().padStart(2, "0");
        let hour = dt.getHours().toString().padStart(2, "0");
        let minute = dt.getMinutes().toString().padStart(2, "0");
        let second = dt.getSeconds().toString().padStart(2, "0");
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      }
    },
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      this.loading = true;
      let params = {
        page: this.page,
        size: this.size,
        parentId: this.id,
        status: this.numStatus,
      };
      let res = list(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.tableData.forEach((v) => {
          v.s_create_time = this.getYMDHMS(
            "YYYY-mm-dd HH:MM:SS",
            v.s_create_time
          );
        });
        this.loading = false;
        this.pageTotal = res.data.total;
      });
    },
    // 是否开通短信
    changeSwitch(callback, row) {
      console.log("是否开通短信", row);
      if (row.status == 0) {
        row.status = 1;
        this.$confirm("是否要关闭号码检测功能", "关闭", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          row.status = 0;
          this.upadd(row);
        });
      } else {
        row.status = 0;
        this.$confirm("是否要开通号码检测功能", "开通", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          row.status = 1;
          this.upadd(row);
        });
      }
    },
    // 编辑
    async upadd(row) {
      let params = {
        parentId: row.parentId,
        rate: row.rate,
        status: row.status,
      };

      let res = await update(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
      this.getList();
    },
    // 编辑
    update(row) {
      // console.log("当前行的值", row.row);
      this.form = { ...row.row };
      this.updateVisible = true;
    },
    // 编辑确定
    updateSubmit() {
      // let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.upadd(this.form);
      this.updateVisible = false;
    },
    // 充值
    updateadd(row) {
      this.form = { ...row.row };
      this.updateaddVisible = true;
    },
    // 充值确定
    updateaddSubmit() {
      // let parentId = JSON.parse(localStorage.getItem("parentId"));
      let params = {
        parentId: this.form.id,
        id: this.form.sid,
        money: this.form.count,
      };
      this.upadd(params);
    },
    // 查询
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.id = null;
      this.numStatus = null;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
};
</script>

<style lang="less" scoped>
#note {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    // height: 58vh;
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>